import { addMonths, endOfMonth, startOfMonth } from "date-fns";

import { DefinedDateRange } from "./consts";

export const getDefinedDateRange = (type: DefinedDateRange) => {
  const now = new Date();
  switch (type) {
    case DefinedDateRange.THIS_MONTH:
      return [startOfMonth(now), endOfMonth(now)];
    case DefinedDateRange.NEXT_3_MONTHS:
      return [now, addMonths(now, 3)];
    case DefinedDateRange.NEXT_6_MONTHS:
      return [now, addMonths(now, 6)];
    case DefinedDateRange.LAST_3_MONTHS:
      return [addMonths(now, -3), now];
    case DefinedDateRange.LAST_6_MONTHS:
      return [addMonths(now, -6), now];
  }
};
