import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import { endOfDay, startOfDay } from "date-fns";
import { type FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { Spacer } from "scmp-app/components/common/spacer";
import { formatPlusDatePair } from "scmp-app/components/plus/helpers";

import type { DefinedDateRange } from "./consts";
import { DefinedDateRanges } from "./consts";
import { getDefinedDateRange } from "./helpers";
import {
  ActionButtonContainer,
  Container,
  DefinedDateRangeButton,
  DefinedDateRangeContainer,
  SpecificDateText,
  StyledArrowDropDownIcon,
  StyledBox,
  StyledButton,
  StyledChip,
  StyledDateRange,
  StyledPopper,
} from "./styles";
import type { DatePickerState, DateRangePair } from "./types";

export type Props = {
  className?: string;
  dateRange: DateRangePair;
  defaultDateRange?: DateRangePair;
  onChange?: (range: DateRangePair) => void;
};

export const DatePicker: FunctionComponent<Props> = ({
  className,
  dateRange: [startDate, endDate],
  defaultDateRange: [defaultStartDate, defaultEndDate] = [],
  onChange = () => {},
}) => {
  const defaultState = useMemo<DatePickerState>(
    () => ({
      color: "rgba(69, 133, 255, 0.20)",
      definedDateRange: null,
      endDate: defaultEndDate,
      key: "selection",
      startDate: defaultStartDate,
    }),
    [defaultEndDate, defaultStartDate],
  );

  const anchorElement = useRef<HTMLButtonElement | null>(null);
  const [state, setState] = useState<DatePickerState>({ ...defaultState, endDate, startDate });
  const [temporaryState, setTemporaryState] = useState<DatePickerState>(state);
  const [open, setOpen] = useState(false);

  const applyDefinedDateRange = useCallback((range: DefinedDateRange) => {
    const [startDate, endDate] = getDefinedDateRange(range);
    setTemporaryState(current => ({
      ...current,
      definedDateRange: range,
      endDate: endOfDay(endDate),
      startDate: startOfDay(startDate),
    }));
  }, []);

  const handleDateRangeChanged = useCallback((range: RangeKeyDict) => {
    setTemporaryState(current => ({
      ...current,
      ...range.selection,
      endDate: range.selection.endDate ? endOfDay(range.selection.endDate) : undefined,
      startDate: range.selection.startDate ? startOfDay(range.selection.startDate) : undefined,
    }));
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleClearAll = useCallback(() => {
    setTemporaryState(state);
  }, [state]);

  const handleReset = useCallback(() => {
    setTemporaryState(defaultState);
    setState(defaultState);
    onChange([defaultStartDate, defaultEndDate]);
  }, [defaultEndDate, defaultStartDate, defaultState, onChange]);

  const handleApply = useCallback(() => {
    setState(temporaryState);
    onChange([temporaryState.startDate, temporaryState.endDate]);
    setOpen(false);
  }, [onChange, temporaryState]);

  useEffect(() => {
    if (!open) handleClearAll();
  }, [handleClearAll, open]);

  const isShowChip = useMemo(
    () =>
      startDate?.getTime() !== defaultStartDate?.getTime() ||
      endDate?.getTime() !== defaultEndDate?.getTime(),
    [startDate, endDate, defaultStartDate, defaultEndDate],
  );

  return (
    <Container className={className}>
      {startDate && endDate && isShowChip ? (
        <StyledChip
          deleteIcon={<CloseIcon />}
          icon={<DoneIcon />}
          label={state.definedDateRange ?? formatPlusDatePair(startDate, endDate)}
          onDelete={handleReset}
        />
      ) : (
        <>
          <StyledButton
            $open={open}
            endIcon={<StyledArrowDropDownIcon />}
            ref={anchorElement}
            variant="outlined"
            disableRipple
            onClick={() => setOpen?.(current => !current)}
          >
            Date Range
          </StyledButton>

          <StyledPopper
            anchorEl={anchorElement.current}
            disablePortal={true}
            open={open}
            placement="bottom-start"
          >
            <ClickAwayListener onClickAway={handleClose}>
              <StyledBox>
                <DefinedDateRangeContainer>
                  {DefinedDateRanges.map((range, index) => (
                    <DefinedDateRangeButton
                      key={index}
                      variant="text"
                      disableRipple
                      onClick={() => applyDefinedDateRange(range)}
                    >
                      {range}
                    </DefinedDateRangeButton>
                  ))}
                  <SpecificDateText>
                    Specific Dates <ArrowForwardIosIcon />
                  </SpecificDateText>
                </DefinedDateRangeContainer>

                <StyledDateRange
                  dateDisplayFormat="dd/MM/yyyy"
                  fixedHeight={true}
                  ranges={[temporaryState]}
                  onChange={handleDateRangeChanged}
                />

                <ActionButtonContainer>
                  <Button variant="text" onClick={handleClearAll}>
                    Clear All
                  </Button>
                  <Spacer />
                  <Button variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="text" onClick={handleApply}>
                    Apply
                  </Button>
                </ActionButtonContainer>
              </StyledBox>
            </ClickAwayListener>
          </StyledPopper>
        </>
      )}
    </Container>
  );
};

DatePicker.displayName = "DatePicker";
